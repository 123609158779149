
import { Options, Vue } from 'vue-class-component';
import { ChallengeClient, GameClient } from '@/services/Services';
import InfoButton from '../components/infoButton.vue';
import * as OM from '@/Model';

@Options({
    components: {
        InfoButton
    }
})
export default class ChallengeList extends Vue {

    stages: OM.StageVM[] = [];
    gameResume: OM.UserGameResume = new OM.UserGameResume();
    
    created() {
        Promise.all([
            ChallengeClient.getAllStagesInfo(),
            GameClient.getUserGameResume()
        ])
        .then(xs => {
            this.stages = xs[0];
            this.gameResume = xs[1];
        })
    }

    getChallengeStatus(challengeId: string) {
        if(!this.gameResume)
            return "discovery";

        var exist = this.gameResume.stagesDone.find(x => x.stageIdentifier == challengeId);

        if(!exist)
            return "discovery";

        return exist.completedOn ? 'completed' : 'pending';
    }

}
